import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25456612"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_badges = _resolveComponent("tm-badges")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_admin_workspace_sub_accounts_details_actions = _resolveComponent("admin-workspace-sub-accounts-details-actions")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-avatar": _withCtx((props) => [
      _createElementVNode("div", {
        class: _normalizeClass({ inactive: _ctx.isUserInactive(props)})
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'base.admin.userDetails.profile', params: { id: props.row.id }, query: {status: props.row.userStatus.name} },
          class: "fullwidth-link font-color-inherit font-weight-normal blue-on-hover"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_tm_avatar, {
                url: props.row.userStatus.name !== 'invited' ? props.row.person.avatar : '',
                name: props.row.userStatus.name !== 'invited' ? props.row.person.firstName : '',
                color: props.row.person.avatarColor,
                class: "mr-2"
              }, null, 8, ["url", "name", "color"]),
              _createTextVNode(" " + _toDisplayString(props.row.userStatus.name !== 'invited' ? `${props.row.person.firstName} ${props.row.person.lastName}` : '-'), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to"])
      ], 2)
    ]),
    "body-cell-email": _withCtx((props) => [
      _createVNode(_component_tm_ellipsis, {
        class: _normalizeClass({ inactive: _ctx.isUserInactive(props)})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: 'base', params: { id: props.row.id }, query: {status: props.row.userStatus.name} },
            class: "fullwidth-link font-color-inherit font-weight-normal blue-on-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.email), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 2
      }, 1032, ["class"])
    ]),
    "body-cell-role": _withCtx((props) => [
      _createVNode(_component_router_link, {
        to: { name: 'base', params: { id: props.row.id }, query: {status: props.row.userStatus.name} },
        class: _normalizeClass(["fullwidth-link font-color-inherit font-weight-normal blue-on-hover", { inactive: _ctx.isUserInactive(props)}])
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.roleBadges ? 'tm-badge' : 'div'), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.userRole), 1)
            ]),
            _: 2
          }, 1024))
        ]),
        _: 2
      }, 1032, ["to", "class"])
    ]),
    "body-cell-teams": _withCtx((props) => [
      _createVNode(_component_tm_badges, {
        items: props.row.userTeams,
        "visible-items": 2
      }, null, 8, ["items"])
    ]),
    "body-cell-status": _withCtx((props) => [
      (props.row?.userStatus)
        ? (_openBlock(), _createBlock(_component_tm_status, _normalizeProps(_mergeProps({ key: 0 }, props.row?.userStatus)), null, 16))
        : _createCommentVNode("", true)
    ]),
    "body-cell-user-fa": _withCtx((props) => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_tm_icon, {
          class: _normalizeClass([{ 'text-red': !props.row.userFa, 'text-green': props.row.userFa }, "mr-2"]),
          name: props.row.userFa ? 'verified_user' : 'close'
        }, null, 8, ["class", "name"]),
        _createElementVNode("span", {
          textContent: _toDisplayString(props.row.userFa ? 'Enabled' : 'Disabled')
        }, null, 8, _hoisted_3)
      ])
    ]),
    "body-cell-last-active": _withCtx((props) => [
      _createElementVNode("div", {
        class: _normalizeClass({ inactive: _ctx.isUserInactive(props)})
      }, _toDisplayString(_ctx.formatDate(props.row.lastActive)), 3)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_admin_workspace_sub_accounts_details_actions)
    ]),
    _: 1
  }))
}