
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAutoSizePanelItem from '@/components/shared/autoSize/panel/TmAutoSizePanelItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { TopFilterDropdownMenuItem } from '@/definitions/_layout/topFilter/types'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TmAutoSizePanelItem,
    TmDropdown,
  },
  props: {
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    iconOnly: {
      type: Boolean,
    },
    items: {
      type: Object as PropType<Record<string, TopFilterDropdownMenuItem>>,
      default: () => ({}),
    },
  },
})
