
import { defineComponent, ref } from 'vue'
import DetailsGrid from '@/components/shared/templates/details/DetailsGrid.vue'
import AdminWorkspaceSubAccountsSidebar from '@/components/pages/admin/workspace/subAccounts/details/AdminWorkspaceSubAccountsSidebar.vue'
import { getTableData } from '@/services/tableService'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import AdminWorkspaceSubAccountsDetailsFilter from '@/components/pages/admin/workspace/subAccounts/details/AdminWorkspaceSubAccountsDetailsFilter.vue'
import { useModes } from '@/compositions/modes'
import AdminWorkspaceSubAccountsDetailsEmpty from '@/components/pages/admin/workspace/subAccounts/details/AdminWorkspaceSubAccountsDetailsEmpty.vue'
import AdminWorkspaceSubAccountsDetailsTable from '@/components/pages/admin/workspace/subAccounts/details/AdminWorkspaceSubAccountsDetailsTable.vue'

export default defineComponent({
  components: {
    AdminWorkspaceSubAccountsDetailsTable,
    AdminWorkspaceSubAccountsDetailsEmpty,
    AdminWorkspaceSubAccountsDetailsFilter,
    WhiteBlock,
    AdminWorkspaceSubAccountsSidebar,
    DetailsGrid,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const selected = ref<any[]>([])
    const usersHeaders = ref([
      { text: 'Name', value: 'avatar', width: '20%', required: true, field: (row: any) => row.person.firstName },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'role', field: (row: any) => row.userRole },
      { text: 'Status', value: 'status', field: (row: any) => row.userStatus.name },
      { text: 'Last active', value: 'last-active', width: '10%' },
    ])

    const users = ref(getTableData('usersAdmin'))

    return {
      isEmptyMode,
      selected,
      users,
      usersHeaders,
    }
  },
})
