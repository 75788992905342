
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useWorkspace from '@/compositions/admin/useWorkspace'

export default defineComponent({
  components: { TmTableActionButton, TmDropdownItem, TmDropdown },
  setup() {
    const { openDeactivateUserModal, openRemoveUsersSubAccountModal } = useWorkspace()

    return {
      openRemoveUsersSubAccountModal,
      openDeactivateUserModal,
    }
  },
})
