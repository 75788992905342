
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TopFilterDropdown from '@/components/layout/topFilter/TopFilterDropdown.vue'
import useWorkspace from '@/compositions/admin/useWorkspace'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilterDropdown,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { openAddUsersModal, openRemoveUsersSubAccountModal, openDeactivateUsersModal } = useWorkspace()
    const { openModal } = useModals()
    const search = ref('')
    const items = [
      {
        icon: 'people',
        label: 'Add from existing',
        action: () => openAddUsersModal(),
      },
      {
        icon: 'email',
        label: 'Invite new users',
        action: () => openModal('workspaceUsersEmail'),
      },
    ]

    return {
      search,
      items,
      openRemoveUsersSubAccountModal,
      openDeactivateUsersModal,
    }
  },
})
