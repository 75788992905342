
import { computed, defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import DetailsGridSidebar from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import useWorkspace from '@/compositions/admin/useWorkspace'

export default defineComponent({
  components: {
    TmStatus,
    TmButton,
    TmFieldsContainer,
    DetailsGridSidebar,
  },
  setup() {
    const { isMdMax } = useBreakpoints()
    const name = ref('Adela Test’s sub-account')
    const id = ref('3225343')
    const description = ref('This account is for the local shop management and communications in California')

    const styleProps = computed(() => {
      return {
        leftLabel: !isMdMax.value,
        inputContainerClass: isMdMax.value ? 'mx-n2' : '',
      }
    })
    const statusValue = ref({ name: 'Active', color: 'green', outline: true })
    const { openEditSubAccountModal } = useWorkspace()

    return {
      id,
      openEditSubAccountModal,
      statusValue,
      styleProps,
      description,
      name,
    }
  },
})
