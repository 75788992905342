
import { defineComponent } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import TmBadges from '@/components/shared/TmBadges.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import AdminWorkspaceSubAccountsDetailsActions from '@/components/pages/admin/workspace/subAccounts/details/AdminWorkspaceSubAccountsDetailsActions.vue'

export default defineComponent({
  components: {
    TmBadge,
    TmEllipsis,
    TmAvatar,
    TmTable,
    TmStatus,
    TmBadges,
    AdminWorkspaceSubAccountsDetailsActions,
  },
  props: {
    roleBadges: {
      type: Boolean,
    },
  },
  setup() {
    const isUserInactive = (e: any) => {
      return e.row.userStatus.name === 'deactivated'
    }

    return {
      formatDate,
      isUserInactive,
    }
  },
})
